<template>
  <div class="copyLabel">
    <div class="cursor-pointer d-flex" @click="copy">
      <i class="fas fa-copy copy-icon"></i>
      <slot v-if="hasSlot"></slot>
      <template v-else>{{ value }}</template>
    </div>
    <transition name="fade">
      <div class="copyButton" v-if="this.show">
        <i class="fa fa-check"></i>
        Kopyalandı!
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'CopyButtonLabel',
  props: { value: String },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    hasSlot() {
      return !!this.$slots.default;
    },
  },
  methods: {
    copy() {
      if (!this.show) {
        const el = document.createElement('textarea');
        el.value = this.value;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        const selected =
          document.getSelection().rangeCount > 0
            ? document.getSelection().getRangeAt(0)
            : false;
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        if (selected) {
          document.getSelection().removeAllRanges();
          document.getSelection().addRange(selected);
        }

        this.show = true;
        setTimeout(() => {
          this.show = false;
        }, 1500);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.copyLabel {
  display: inline-block;
  position: relative;
  width: 100%;
  .copy-icon {
    font-size: 14px;
    color: #464e5f;
    margin-right: 5px;
    padding-top: 3px;
    padding-right: 3px;
    opacity: 0.7;
  }

  .copyButton {
    right: -60px;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 2;
    background: #ffffff;
    border-radius: 4px;
    font-size: 12px;
    line-height: 18px;
    color: #0bb783;
    padding: 10px;
    & i {
      margin-right: 10px;
      font-size: 12px;
      line-height: 18px;
      color: #0bb783;
    }
    // &.show {
    //   opacity: 1;
    //   transition: opacity 1s;
    // }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
